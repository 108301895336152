<template>
  <div>
    <h3>{{  $t('PriceFrom')   }} {{ totalPrice }} {{ BUSINESS_CURRENCY }}</h3>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "elementCourseNameEditor",
  computed : {
    ...mapState('avalonPage',['course']),
    totalPrice() {

      if ( this.course.has_payment_plans) {
        if ( this.enrollmentData.payment_plan_id ) {
          let course = this.course.payment_plans.find(el => el.value === this.enrollmentData.payment_plan_id)
          return course.price
        }
      }

      return this.course.price

    }
  }
}
</script>

<style scoped>

</style>